import { colorLookup } from '@medifind/utils';
import { SVG } from './SVG';
const IconClose = ({ color, size, strokeWidth, circle = true }) => {
  let height;
  let width;
  let _strokeWidth;
  const hexColor = colorLookup(color) ?? colorLookup('blue');

  switch (size) {
    case 's':
    case 'sm':
    case 'small':
      width = height = 14;
      break;
    case 'm':
    case 'md':
    case 'medium':
      width = height = 22;
      break;
    case 'l':
    case 'lg':
    case 'large':
      width = height = 28;
      break;
    case 'xl':
    case 'xlarge':
      width = height = 30;
      break;
    default:
      width = height = 28;
      break;
  }

  switch (strokeWidth) {
    case 'thin':
      _strokeWidth = '1.0';
      break;
    case 'thick':
    default:
      _strokeWidth = '1.5';
  }

  return (
    <SVG width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      {circle && (
        <path
          d="M7.48529 13C10.799 13 13.4853 10.3137 13.4853 7C13.4853 3.68629 10.799 1 7.48529 1C4.17158 1 1.48529 3.68629 1.48529 7C1.48529 10.3137 4.17158 13 7.48529 13Z"
          stroke={hexColor}
          strokeWidth={_strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <path
        d="M9.28506 5.19983L5.68506 8.79983"
        stroke={hexColor}
        strokeWidth={_strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68506 5.19983L9.28506 8.79983"
        stroke={hexColor}
        strokeWidth={_strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { IconClose as default, IconClose };
