import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { compareFilterStatesEqual } from '../filterValue';

const initialState = {
  name: 'doctorSearchRadius',
  type: 'singleSelect',
  selected: null,
  isSuggested: true,
};
const storeName = 'doctorSearchRadius';
export const useDoctorSearchRadiusFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        compareStates: (v1, v2) => (v1.isSuggested && v2.isSuggested) || compareFilterStatesEqual(v1, v2),
        setFilterValue: (v) => updateDoctorSearchRadius(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'doctorSearchRadius' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateDoctorSearchRadius = (option, isSuggested = false) => {
  useDoctorSearchRadiusFilter.setState({ selected: option, isSuggested });
};
export const clearDoctorSearchRadius = () =>
  useDoctorSearchRadiusFilter.setState({ ...initialState }, false, {
    type: 'clearDoctorSearchRadiusOption',
  });
